const iconsNames = {
  appleLarge: "/assets/icons/social/apple-large.svg",
  apple: "/assets/icons/social/apple.svg",
  facebookLarge: "/assets/icons/social/facebook-large.svg",
  facebook: "/assets/icons/social/facebook.svg",
  facebookColor: "/assets/icons/social/facebook_color.svg",
  googleLarge: "/assets/icons/social/google-large.svg",
  instagram: "/assets/icons/social/instagram.svg",
  instagramColor: "/assets/icons/social/instagram_color.svg",
  playmarket: "/assets/icons/social/playmarket.svg",
  twitter: "/assets/icons/social/twitter.svg",
  x: "/assets/icons/social/x.svg",
  xColor: "/assets/icons/social/x_color.svg",
  youtube: "/assets/icons/social/youtube.svg",
  youtubeColor: "/assets/icons/social/youtube_color.svg",
  telegram: "/assets/icons/social/telegram.svg",
  telegramColor: "/assets/icons/social/telegram_color.svg",
  viber: "/assets/icons/social/viber.svg",
  viberColor: "/assets/icons/social/viber_color.svg",
  addPlus: "/assets/icons/add-plus.svg",
  arrowBottom: "/assets/icons/arrow-bottom.svg",
  arrowCaretDown: "/assets/icons/arrow-caret-down.svg",
  arrowDown: "/assets/icons/arrow-down.svg",
  arrowDownGrey: "/assets/icons/arrow-down-grey.svg",
  arrowDownBorder: "/assets/icons/arrow-down-border.svg",
  arrowRight: "/assets/icons/arrow-right.svg",
  arrowRight20: "/assets/icons/arrow-right-20.svg",
  arrowRightWhite: "/assets/icons/arrow-right-white.svg",
  arrowShortLeft: "/assets/icons/arrow-short-left.svg",
  arrowShortLeftGrey: "/assets/icons/arrow-short-left-grey.svg",
  arrowShortRight: "/assets/icons/arrow-short-right.svg",
  arrowShortRightGrey: "/assets/icons/arrow-short-right-grey.svg",
  arrowUp: "/assets/icons/arrow-up.svg",
  ask: "/assets/icons/ask.svg",
  attachFile: "/assets/icons/attach-file.svg",
  attachFileDefault: "/assets/icons/attach-file-default.svg",
  attention: "/assets/icons/attention.svg",
  authSignIn: "/assets/icons/auth-sign-in.svg",
  authSignUp: "/assets/icons/auth-sign-up.svg",
  bell: "/assets/icons/bell.svg",
  bluePoint: "/assets/icons/blue-point.svg",
  blurry: "/assets/icons/blurry.svg",
  busGreen: "/assets/icons/bus-green.svg",
  bus: "/assets/icons/bus.svg",
  camera: "/assets/icons/camera.svg",
  caretDown: "/assets/icons/caret-down.svg",
  caretRight: "/assets/icons/caret-right.svg",
  cartBig: "/assets/icons/cart-big.svg",
  cart: "/assets/icons/cart.svg",
  cartGrey: "/assets/icons/cart-grey.svg",
  check: "/assets/icons/check.svg",
  checkmark: "/assets/icons/checkmark.svg",
  close: "/assets/icons/close.svg",
  close2: "/assets/icons/close2.svg",
  close2red: "/assets/icons/close2red.svg",
  comment: "/assets/icons/comment.svg",
  copyLink: "/assets/icons/copy-link.svg",
  creditCard: "/assets/icons/credit-card.svg",
  cubes: "/assets/icons/cubes.svg",
  currency: "/assets/icons/currency.svg",
  denied: "/assets/icons/denied.svg",
  edit: "/assets/icons/edit.svg",
  exit: "/assets/icons/exit.svg",
  feedback: "/assets/icons/feedback.svg",
  filter: "/assets/icons/filter.svg",
  guard: "/assets/icons/guard.svg",
  hamburger: "/assets/icons/hamburger.svg",
  heartFill: "/assets/icons/heart-fill.svg",
  heartNoFill: "/assets/icons/heart-no-fill.svg",
  heart: "/assets/icons/heart.svg",
  heartOutline: "/assets/icons/heart-outline.svg",
  heartOutlineGrey: "/assets/icons/heart-outline-grey.svg",
  help: "/assets/icons/help.svg",
  home: "/assets/icons/home.svg",
  image: "/assets/icons/image.svg",
  imagePlaceholder: "/assets/icons/image-placeholder.svg",
  imagePlaceholderSmall: "/assets/icons/image-placeholder-small.svg",
  info: "/assets/icons/info.svg",
  infoGrey: "/assets/icons/info-grey.svg",
  filePDF: "/assets/icons/file-pdf.webp",
  labelCartApplePay: "/assets/icons/label-cart-apple-pay.svg",
  labelCartMaster: "/assets/icons/label-cart-master.svg",
  labelCartVisa: "/assets/icons/label-cart-visa.svg",
  like: "/assets/icons/like.svg",
  listUi: "/assets/icons/list-ui.svg",
  location: "/assets/icons/location.svg",
  logout: "/assets/icons/logout.svg",
  logoShop: "/assets/icons/logo-shop.svg",
  logoLiqpayBlack: "/assets/icons/logo_liqpay_black.svg",
  plataByMonoBlack: "/assets/icons/plata_by_mono_black.svg",
  map: "/assets/icons/map.svg",
  menuVertical: "/assets/icons/menu-vertical.svg",
  menuVerticalGrey: "/assets/icons/menu-vertical-grey.svg",
  dragButton: "/assets/icons/drag-button.svg",
  closeMenu: "/assets/icons/close-menu.svg",
  bluePlus: "/assets/icons/blue-plus.svg",
  menu: "/assets/icons/menu.svg",
  minus: "/assets/icons/minus.svg",
  mobileLogin: "/assets/icons/mobile-login.svg",
  notificationsCar: "/assets/icons/notifications-car.svg",
  notificationsCart: "/assets/icons/notifications-cart.svg",
  notificationsComment: "/assets/icons/notifications-comment.svg",
  notificationsGift: "/assets/icons/notifications-gift.svg",
  notifications: "/assets/icons/notifications.svg",
  notificationsGrey: "/assets/icons/notifications-grey.svg",
  order: "/assets/icons/order.svg",
  orders: "/assets/icons/orders.svg",
  picture: "/assets/icons/picture.svg",
  placeholderImage: "/assets/icons/placeholder-image.svg",
  play: "/assets/icons/play.svg",
  plus: "/assets/icons/plus.svg",
  question: "/assets/icons/question.svg",
  refresh: "/assets/icons/refresh.svg",
  report: "/assets/icons/report.svg",
  reviews: "/assets/icons/reviews.svg",
  saleImage: "/assets/icons/sale-image.svg",
  search: "/assets/icons/search.svg",
  securing: "/assets/icons/securing.svg",
  send: "/assets/icons/send.svg",
  sent: "/assets/icons/sent.svg",
  settingsActive: "/assets/icons/settings-active.svg",
  settings: "/assets/icons/settings.svg",
  share: "/assets/icons/share.svg",
  socialNetwork: "/assets/icons/social-network.svg",
  starFill: "/assets/icons/star-fill.svg",
  starFilled: "/assets/icons/star-filled.svg",
  starNoFill: "/assets/icons/star-no-fill.svg",
  starNoFillGrey: "/assets/icons/star-no-fill-grey.svg",
  star: "/assets/icons/star.svg",
  successPurchase: "/assets/icons/success-purchase.svg",
  paymentCanceled: "/assets/icons/payment-canceled.svg",
  receipt: "/assets/icons/receipt.svg",
  terms: "/assets/icons/terms.svg",
  trash: "/assets/icons/trash.svg",
  unwatch: "/assets/icons/unwatch.svg",
  upload: "/assets/icons/upload.svg",
  user: "/assets/icons/user.svg",
  userGray: "/assets/icons/user_gray.svg",
  warning: "/assets/icons/warning.svg",
  warrantyRed: "/assets/icons/warranty-red.svg",
  warranty: "/assets/icons/warranty.svg",
  watch: "/assets/icons/watch.svg",
  world: "/assets/icons/world.svg",
  shopperLogo: "/assets/icons/shopper-logo.svg",
  qrCode: "/assets/icons/qr-code.svg",
  csvFile: "/assets/icons/csv-file.svg",
  verificationSent: "/assets/icons/verification-sent.svg",
  whiteSent: "/assets/icons/white-sent.svg",
  dropArea: "/assets/icons/drop-area.svg",
  jpg: "/assets/icons/jpg.svg",
  blueArrowLeft: "/assets/icons/blue-arrow-left.svg",
  email: "/assets/icons/email.svg",
  phone: "/assets/icons/phone.svg",
  checkGrey: "/assets/icons/check-grey.svg",
  magic: "/assets/icons/magic.svg",
  bright: "/assets/icons/bright.svg",
  rotate: "/assets/icons/rotate.svg",
  crop: "/assets/icons/crop.svg",
  zoomIn: "/assets/icons/zoom-in.svg",
  menu3: "/assets/icons/menu-3.svg",
  avatar: "/assets/icons/avatar.svg",
  review: "/assets/icons/review.svg",
  subscribe: "/assets/icons/subscribe.svg",
  downloadIOS: "/assets/icons/download_iOS.svg",
  downloadAndroid: "/assets/icons/download_android.svg",
  mallprixLogo: "/assets/icons/mallprix_logo.svg",
  mallprixLogoV2: "/assets/icons/logo_v2.svg",
  mallprixLogoNameV2: "/assets/icons/logo_name_v2.svg",
  mallprixLogoV3: "/assets/icons/logo_v3.svg",
  mallprixLogoNameV3: "/assets/icons/logo_name_v3.svg",
  mallprixLogoFooter: "/assets/icons/logo_footer.svg",
  copyGray: "/assets/icons/copy_gray.svg",
  subscription: "/assets/icons/subscription.svg",
  subscriptionConfirm: "/assets/icons/subscription-confirm.svg",
  image404: "/assets/404.svg",
  image500: "/assets/500.svg",
  kyivstar: "/assets/icons/kyivstar.svg",
  vodafone: "/assets/icons/vodafone.svg",
  lifecell: "/assets/icons/lifecell.svg",
};

export default iconsNames;
