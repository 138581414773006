import { useMemo } from "react";
import NextImage, { ImageProps } from "next/image";
import cn from "@/libs/cn";
import { DEFAULT_PRODUCT_PICTURE } from "@/constants/common";

import styles from "./styles.module.scss";
import {IApiGalleryFileTarget} from '@/types/interfaces/gallery';
import {useImageOptimizationData} from '@/hooks/useImageOptimizationData';

const UNDEFINED_ALT = "*image alt*";
export interface PropsBaseImage extends Omit<ImageProps, "src" | "alt" | "objectFit"> {
  alt?: string;
  src?: string;
  objectFit?: NonNullable<JSX.IntrinsicElements["img"]["style"]>["objectFit"];
  media?: boolean;
  target?: IApiGalleryFileTarget;
}

const Image = ({
  src,
  media,
  className,
  unoptimized,
  width,
  height,
  objectFit = "cover",
  fill,
  style = {},
  loading = "lazy",
  priority = false,
  onClick,
  target = "",
  ...rest
}: PropsBaseImage) => {
  let preSrc = useMemo(() => {
    if (media && !`${src}`.startsWith("http")) {
      console.error("Image url without host", src);
    }

    return `${src}`;
  }, [media, src]);

  const alt = useMemo(() => {
    if (rest.alt) {
      return rest.alt;
    }

    const arr = src?.split("/");
    if (!Array.isArray(arr)) {
      return UNDEFINED_ALT;
    }

    return arr[arr.length - 1] || UNDEFINED_ALT;
  }, [rest.alt, src]);

  const existSize = !!width || !!height;

  if (existSize) {
    if (fill) {
      console.error("Image: setting width/height with fill not allow", src, height, width, fill);
    } else if (!width || !height) {
      console.error("Image: height and width must be to set", src, height, width);
    }
  }

  const { srcset, sizes, targetUrl } = useImageOptimizationData({
    src: preSrc !== "undefined" ? preSrc : DEFAULT_PRODUCT_PICTURE,
    target,
    imageWidth: width ? +width || 0 : 0,
  });

  if(target && !width) {
    return (
      <div style={{ position: "relative", ...style }} className={cn(styles.FillImage, className)}>
        <img
          src={preSrc !== "undefined" ? preSrc : DEFAULT_PRODUCT_PICTURE}
          srcSet={srcset}
          sizes={sizes}
          style={{
            objectFit,
            position:"absolute",
            height:"100%",
            width:"100%",
            left:0,
            top:0,
            right:0,
            bottom:0,
          }}
          {...rest}
          width={width}
          height={height}
          alt={alt}
          onClick={onClick}
          loading={priority ? undefined : loading}
        />
      </div>
    )
  }

  return fill ? (
    <div style={{ position: "relative", ...style }} className={cn(styles.FillImage, className)}>
      <NextImage
        src={target ? targetUrl : preSrc !== "undefined" ? preSrc : DEFAULT_PRODUCT_PICTURE}
        unoptimized={!!target || unoptimized || media || !!process.env.NEXT_PUBLIC_UNOPTIMIZED_IMAGE}
        style={{ objectFit }}
        {...rest}
        fill
        width={width}
        height={height}
        alt={alt}
        onClick={onClick}
        loading={priority ? undefined : loading}
        priority={priority}
      ></NextImage>
    </div>
  ) : (
    <NextImage
      className={cn(styles.Image, className)}
      src={target ? targetUrl : preSrc !== "undefined" ? preSrc : DEFAULT_PRODUCT_PICTURE}
      unoptimized={!!target || unoptimized || media || !!process.env.NEXT_PUBLIC_UNOPTIMIZED_IMAGE}
      style={{ objectFit, ...style }}
      {...rest}
      fill={typeof fill === "undefined" ? !existSize : fill}
      width={width}
      height={height}
      alt={alt}
      onClick={onClick}
      loading={loading}
    ></NextImage>
  );
};

export default Image;
