import iconsNames from "./icons-names";
import Image, { PropsBaseImage } from "@/components/basic/Image";

type Names = keyof typeof iconsNames;
interface PublicIcons extends PropsBaseImage {
  name: Names;
}

const PublicIcons = (props: PublicIcons) => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <Image {...props} src={iconsNames[props.name]} unoptimized />;
};

export default PublicIcons;
