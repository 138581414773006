import {IApiGalleryFileTarget} from '@/types/interfaces/gallery';
import {useMemo} from 'react';

export const IMAGE_SIZES_BY_TARGET: any = {
  "product": {
    "tiny": 88,
    "small": 196,
    "medium": 305,
    "big": 630,
  },
  "store_logo": {
    "tiny": 88,
  },
  "store_banner": {
    "medium": 340,
    "big": 955,
  },
  "product_review": {
    "tiny": 88,
    "big": 630,
  },
}

const getImageCacheSrc = (src: string) => {
  let imgSrcParts = src.split(".");
  imgSrcParts.pop();
  let imgPath = imgSrcParts.join(".");

  imgSrcParts = imgPath.split("/media/");
  return `${imgSrcParts[0]}/media/cache/${imgSrcParts[1]}`;
}

interface ImageOptimizationDataParams {
  src: string;
  target: IApiGalleryFileTarget;
  imageWidth: number;
}

export const useImageOptimizationData = ({ src, target, imageWidth }: ImageOptimizationDataParams) => {
  const sizes = `100vw`;

  const imageCacheSrc = useMemo(() => getImageCacheSrc(src), [src]);
  const srcset = useMemo(() => {
    if(!target) return "";
    let temp = "";
    Object.entries(IMAGE_SIZES_BY_TARGET[target]).forEach(([suffix, width]) => {
      temp += `${imageCacheSrc}_${suffix}.webp ${width}w , `
    });
    return temp;
  }, [imageCacheSrc, target]);

  const targetUrl = useMemo(() => {
    if(!imageWidth || !target) return src;
    let fittedSuffix = "";
    let minFittedSize: number = Number.MAX_SAFE_INTEGER;
    Object.entries(IMAGE_SIZES_BY_TARGET[target]).forEach(([suffix, s]) => {
      const size = Number(s);
      if((imageWidth <= size && size <= minFittedSize)) {
        minFittedSize = size;
        fittedSuffix = suffix;
      }
    });
    if(!fittedSuffix) {
      const [suffix, _] = Object.entries(IMAGE_SIZES_BY_TARGET[target])[0];
      fittedSuffix = suffix;
    }
    return `${imageCacheSrc}_${fittedSuffix}.webp`;
  }, [imageCacheSrc, imageWidth, src, target]);

  return {
    srcset,
    sizes,
    targetUrl,
  }
}
