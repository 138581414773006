import cn from "@/libs/cn";
import React, { useCallback, useMemo } from "react";
import LoaderCircle from "@/controls/LoaderCircle";
import Link from "@/components/basic/Link";

import styles from "./styles.module.scss";

export interface PropsButton {
  type?: "submit" | "button" | "reset" | "link";
  rel?: string;
  target?: string;
  children?: any;
  text?: string;
  title?: string;
  href?: string;
  size?: "small" | "medium" | "large";
  color?: "primary" | "secondary" | "warning" | "success";
  variant?: "contained" | "text" | "outlined";
  onClick?: (e: Event) => void;
  icon?: (props: any) => JSX.Element;
  leftIcon?: boolean;
  width?: string | number;
  height?: string | number;
  className?: string;
  classNameText?: string;
  classNameIcon?: string;
  classNameLoader?: string;
  loading?: boolean;
  loaderColor?: string;
  disabled?: boolean;
  dispayInline?: boolean;
}

const Button = ({
  type = "button",
  rel,
  target,
  children,
  text,
  title,
  href,
  size = "medium",
  color,
  variant = "contained",
  onClick = () => {},
  icon: Icon,
  leftIcon,
  width,
  height,
  className,
  classNameText,
  classNameIcon,
  classNameLoader,
  loaderColor: loaderColorOut,
  loading,
  disabled,
  dispayInline = true,
  ...rest
}: PropsButton) => {
  const onClickHandler = useCallback(
    (e: Event) => {
      if (loading) {
        return;
      }

      onClick(e);
    },
    [loading, onClick]
  );

  const loaderColor = useMemo(() => {
    if (loaderColorOut) {
      return loaderColorOut;
    }
    if (color === "primary") {
      return "#0D7D8B"; //TODO
      // return "#007aff";
      // return "#06768D";
    }
    if (color === "secondary") {
      return "#191c1f";
    }
    if (color === "warning") {
      return "#f8655c";
    }
    if (color === "success") {
      return "#22B30A";
    }
  }, [color, loaderColorOut]);

  const Tag = !!href ? Link : "button";

  return (
    <Tag
      type={type !== "link" ? type : undefined}
      rel={rel}
      target={target}
      className={cn(
        styles.Button,
        size === "small" && styles.sizeSmall,
        size === "large" && styles.sizeLarge,
        color === "primary" && styles.colorPrimary,
        color === "secondary" && styles.colorSecondary,
        color === "warning" && styles.colorWarning,
        color === "success" && styles.colorSuccess,
        variant === "contained" && styles.variantContained,
        variant === "text" && styles.variantText,
        variant === "outlined" && styles.variantOutlined,
        className
      )}
      href={href || '#'}
      title={title}
      // @ts-ignore
      onClick={onClickHandler}
      style={{ width, height, display: dispayInline ? "inline-flex" : "flex" }}
      disabled={disabled}
      {...rest}
    >
      {/* @ts-ignore */}
      {Icon && leftIcon && <Icon className={cn(styles.leftIcon, classNameIcon)} />}
      <span className={cn(styles.text, classNameText)}>{text || children}</span>
      {/* @ts-ignore */}
      {Icon && !leftIcon && <Icon className={classNameIcon} />}
      {loading && <LoaderCircle size={20} className={cn(styles.loader, classNameLoader)} color={loaderColor} />}
    </Tag>
  );
};

export default Button;
